import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import Layout from "../components/layout"
import Slider2 from "../components/slider2"
import SEO from "../components/seo"
import { Link, navigate } from "gatsby"
import { register } from "../store/actions/authActions"

const Register = ({ isAuthenticated, register, errors }) => {
  const [name, setName] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [showError, setShowError] = useState(true)

  const handleRegister = e => {
    e.preventDefault()
    register({
      name,
      phoneNumber,
      email,
      password,
    })
      .then(() => {
        navigate("/cart")
      })
      .catch(() => {
        console.log("error")
      })
  }

  useEffect(() => {}, [])
  const pageName = "CREATION DE COMPTE"
  return (
    <Layout>
      <SEO title={pageName} />
      <Slider2 title={pageName} />
      <div className="flex flex-wrap py-6">
        <div className="container mx-auto">
          <form
            onSubmit={handleRegister}
            className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
          >
            <div className="text-center text-2xl py-5">INSCRIPTION</div>
            {errors && showError && (
              <div
                className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative my-2"
                role="alert"
              >
                <strong className="font-bold">Oups !</strong>&nbsp;
                <span className="block sm:inline">
                  Veuillez correctement renseigner tous les champs s'il vous
                  plaît.
                </span>
                <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                  <svg
                    className="fill-current h-6 w-6 text-red-500"
                    role="button"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    onClick={() => setShowError(!showError)}
                  >
                    <title>Close</title>
                    <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
                  </svg>
                </span>
              </div>
            )}
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="fullname"
              >
                Nom complet
              </label>
              <input
                className={
                  errors && errors.name
                    ? "shadow text-sm appearance-none border border-red-600 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                    : "shadow text-sm appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                }
                id="fullname"
                type="text"
                value={name}
                onChange={e => setName(e.target.value)}
              />
              <span className="text-green-600 text-xs italic">
                {errors ? errors.name : ""}
              </span>
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="phone-number"
              >
                N° de téléphone
              </label>
              <input
                className={
                  errors && errors.phone_numbers
                    ? "shadow text-sm appearance-none border border-red-600 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                    : "shadow text-sm appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                }
                id="phone-number"
                type="text"
                value={phoneNumber}
                onChange={e => setPhoneNumber(e.target.value)}
              />
              <span className="text-green-600 text-xs italic">
                {errors ? errors.phone_numbers : ""}
              </span>
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="email-address"
              >
                Adresse email
              </label>
              <input
                className={
                  errors && errors.email
                    ? "shadow text-sm appearance-none border border-red-600 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                    : "shadow text-sm appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                }
                id="email-address"
                type="email"
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
              <span className="text-green-600 text-xs italic">
                {errors ? errors.email : ""}
              </span>
            </div>
            <div className="mb-6">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="password"
              >
                Mot de passe
              </label>
              <input
                className={
                  errors && errors.password
                    ? "shadow text-sm appearance-none border border-red-600 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                    : "shadow text-sm appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                }
                id="password"
                type="password"
                value={password}
                onChange={e => setPassword(e.target.value)}
              />
              <span className="text-green-600 text-xs italic">
                {errors ? errors.password : ""}
              </span>
            </div>
            <div className="flex items-center justify-between">
              <button
                className="bg-green-500 hover:bg-green-700 text-white font-bold text-sm py-2 px-5 rounded focus:outline-none focus:shadow-outline"
                type="submit"
              >
                INSCRIPTION
              </button>
            </div>
            <div className="text-center text-xs pt-6">
              <Link to="/login">
                Déjà inscrit ?&nbsp;
                <span className="text-blue-500 hover:text-blue-800 font-bold">
                  Connexion
                </span>
              </Link>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  )
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    errors: state.auth.errors,
  }
}

const mapDispatchToProps = {
  register,
}

export default connect(mapStateToProps, mapDispatchToProps)(Register)
